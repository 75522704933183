import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', color: '#333' }}>Privacy Policy for CND Play Users</h1>
      <p>
        Welcome to CLOCKNDAIL INDUSTRIES PRIVATE LIMITED (“CND Play,” “we,” “us,” or “our”). This Privacy Policy outlines
        how we collect, use, and share information when you use the CND Play Platform (referred to as the “Platform”)
        across our supported devices and applications, including websites, mobile apps, and connected TV platforms
        (e.g., Android TV, Amazon Fire stick, Apple TV, etc).
      </p>
      <p>
        By accessing or using the Platform, you acknowledge that you have read, understood, and agree to the practices
        described in this Privacy Policy. If you disagree, please stop using the Platform.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>A. Personal Information</h3>
      <ul>
        <li>Name, email address, phone number, gender, and other account details.</li>
        <li>Billing and payment information (e.g., credit card details).</li>
        <li>Communications, including emails, messages, and feedback shared with us.</li>
        <li>CND Play collects and uploads your image data to enable personalized features like avatar customization.</li>
      </ul>

      <h3>B. Technical Information</h3>
      <ul>
        <li>Device information: Type, operating system, browser type/version.</li>
        <li>IP address, geolocation, and internet service provider (ISP) details.</li>
        <li>Interaction data: Pages viewed, time spent, clicks, and other usage patterns.</li>
      </ul>

      <h3>C. Cookies and Similar Technologies</h3>
      <ul>
        <li>Authenticate users and enhance functionality.</li>
        <li>Monitor performance and usage analytics.</li>
        <li>Deliver personalized experiences and advertisements.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>To provide, maintain, and improve our Platform and services.</li>
        <li>To personalize content, recommendations, and advertisements.</li>
        <li>To process transactions, subscriptions, or refunds.</li>
        <li>To communicate updates, promotions, or important notifications.</li>
        <li>To ensure legal compliance and detect/prevent fraud.</li>
        <li>To analyze user behavior for service improvement and market research.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <h3>A. Service Providers and Partners</h3>
      <p>To facilitate payment processing, analytics, and advertising services.</p>
      <h3>B. Legal and Security Authorities</h3>
      <p>To comply with legal obligations or respond to valid requests from authorities.</p>
      <h3>C. Business Transfers</h3>
      <p>In case of mergers, acquisitions, or asset sales, your data may be transferred.</p>
      <h3>D. Advertisers</h3>
      <p>
        To provide targeted ads via cookies or similar technologies. No personal information is directly shared without
        consent.
      </p>

      <h2>4. Data Retention and Security</h2>
      <p>
        We retain your data as long as necessary to fulfill the purposes outlined in this policy, comply with legal
        obligations, and resolve disputes.
      </p>
      <p>
        To protect your information, we implement industry-standard measures like encryption, secure servers, and
        regular security assessments. Despite these efforts, no method of transmission or storage is completely secure.
      </p>

      <h2>5. Your Rights</h2>
      <ul>
        <li>Access and review the personal information we hold about you.</li>
        <li>Request correction, deletion, or restriction of your data.</li>
        <li>Withdraw consent where processing is based on consent.</li>
        <li>Opt-out of marketing communications by following the unsubscribe instructions.</li>
        <li>For inquiries or to exercise your rights contact us using the details provided below.</li>
      </ul>

      <h2>6. Children’s Privacy</h2>
      <p>
        Our Platform is not intended for users under the age of 13. We do not knowingly collect personal information
        from children without parental consent. If we become aware of such collection, we will take immediate steps to
        delete the data.
      </p>

      <h2>7. Updates to the Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our practices, legal requirements, or
        technological advancements. The “Last Updated” date will be revised accordingly. Continued use of the Platform
        indicates acceptance of the updated terms.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        For questions, concerns, or complaints about this Privacy Policy or how your information is handled, you can
        reach us at: <a href="mailto:cndplay.help@gmail.com">cndplay.help@gmail.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
